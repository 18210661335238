import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'

import { Box, Typography, Button, Grid, Chip, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'

import EKGViewer from '../../components/EKGViewer'
import EKGViewerOLD from '../../components/EKGViewer/EKGViewerOLD'
import Navigation from '../../components/Navigation'

import { eventTypes } from '../../common/enums'
import { sendEvent } from '../../common/requests'
import { ReviewTag } from './Review.styled';
import Viewer from '../../components/EKGViewer/Viewer';
import { useContext } from 'react';
import { TaskContext } from '../../contexts/Task.context';

const useStyles = makeStyles((theme) => ({
    revertedArrow: {
        transform: 'rotate(180deg)',
        marginRight: 5,
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    taskNumber: {
        fontSize: 16,
    },
    fullWidth: {
        flexGrow: 1,
    },
    backButton: {
        padding: 2,
    },
}))

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function NewReview() {

    const query = useQuery()
    const { id } = useParams()
    const mode = query.get('mode')



    const history = useHistory()
    const classes = useStyles()
    const { getTask, task, loading } = useContext(TaskContext)
    useEffect(() => {
        if (id) {
            getTask(id)
        }
    }, [id])

    const renderMenu = () => {
        const goBack = () => {
            history.push('/' + mode)
        }

        return (
            <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Box onClick={goBack}>
                    <Button className={classes.backButton}>
                        <Typography variant="button" className={classes.goBack}>
                            <ArrowRightAlt className={classes.revertedArrow} />
                            Go back
                        </Typography>
                    </Button>
                </Box>
                <Box>
                    <Typography variant="h6" className={classes.taskNumber}>
                        {mode && <ReviewTag mode={mode} variant="outlined" size="small" color="warning" label={`${mode} Task`} />
                        }    Task: {id}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Navigation renderMenu={renderMenu} />
            {loading && <LinearProgress color="inherit" style={{ opacity: ".3" }} />}

            {/* <EKGViewer
                isAudit={mode === "audit"}
                isLabelling={mode === "labeling"}
                setForceAuditSubmit={setForceAuditSubmit}
                forceAuditSubmit={forceAuditSubmit}
            /> */}

            {task ? <Viewer
                mode={mode}
                task={task}
            /> : <>loading</>}
        </>
    )
}

export default NewReview
