import React, {  useContext, useEffect } from 'react'

import {
  Grid,
  Card,
  CardContent,
  CssBaseline,
} from '@mui/material';
import Navigation from '../../components/Navigation'
import SectionWrapper from '../../components/SectionWrapper'


import TaskTable from '../../components/TaskTable';
import TableHeader from '../../components/TableHeader';
import { TasksContext } from '../../contexts/Tasks.context';



export function Labelling() {
  const { remainingLabelingTasksNumber, labelingTasks, assignLabelingTask, getLabelingTasks } = useContext(TasksContext)

  useEffect(() => {
    getLabelingTasks()
  }, [])

  return (
    <div>
      <CssBaseline />
      <SectionWrapper header={<TableHeader counter={remainingLabelingTasksNumber} title="Labeling Mode" getNewTaskHandler={assignLabelingTask} />}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} align="center">
            <Card>
              <CardContent sx={{padding:"0"}}>
                <TaskTable {...{ taskData : labelingTasks, mode: 'labeling' }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SectionWrapper>
    </div>
  );
}
