import axios from 'axios'
import Toastr from './Toaster';

const endpoints = require('./endpoints')

const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";


// const responseErrorHandler = error => {
//   if (error.response.status === 401) {
//     localStorage.removeItem('authenticated')
//     if(window.location.pathname !== '/'){window.location.replace('/')}
//     console.log("logout")
//   }

//   return Promise.reject(error);
// }


const API = axios.create({
  baseURL: endpoints.APIURL,
  withCredentials: true,
})

API.interceptors.response.use(
  response => handleSuccessResponse(response),
  error => handleErrorResponse(error)
);

const handleSuccessResponse = response => {
  if (response.status === 201) {
    if (response.data.msg) {
      Toastr.success('Created !');
    }
  }
  return response;
};

const handleErrorResponse = axiosErrorObject => {
  if (axiosErrorObject.response?.status === 401) {
    localStorage.removeItem('authenticated')
    setTimeout(() => (window.location.href = "/"), 2000);
  }

  else if (axiosErrorObject.response?.status === 500) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || DEFAULT_ERROR_NOTIFICATION
    );
  }
  else if (axiosErrorObject.response?.status === 400) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "UNVALID PARAMS"
    );
  }
  else if (axiosErrorObject.response?.status === 422) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "Unprocessable Entity"
    );
  }
  else if (axiosErrorObject.response?.status === 404) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "NOT FOUND"
    );
  }
  return Promise.reject(axiosErrorObject);
};


export default API