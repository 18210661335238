// this is basically EKGViewer component, that will render when old version is selected as param - to allow users use old API if necessary
// don't ever try to change anything here - it's a mess - I copy-pasted a lot of components that needs to be remade in v2 API
// just delete this file when new version will be bug free

import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { loadTasksAsync } from '../../features/dashboard/tasksSlice'
import {
  selectReviewErrors,
  setReviewErrors,
} from '../../store/slices/reviewErrors'
import {
  selectReviewWarnings,
  setReviewWarnings,
} from '../../store/slices/reviewWarnings'

import useAsyncReference from '../../hooks/useAsyncReference'

// material UI stuff
import {
  Box,
  Grid,
  CssBaseline,
  useTheme,
  Badge,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
  Paper,
  Checkbox,
  Chip,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  IconButton,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert'

import HeightIcon from '@mui/icons-material/Height'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// graph stuff

import LineChart from '../LineChart'

// utilities
import API from '../../common/api'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import {
  submitStates,
  boxTypes,
  eventTypes,
  sanityErrors as sanityErrorsEnum,
} from '../../common/enums'
import {
  getKeysArray,
  capitalizeFirstLetter,
  elementInViewport,
} from '../../common/utils'
import * as endpoints from '../../common/endpoints'
import { sendEvent } from '../../common/requests'
import { SESSION_COOKIE } from '../../common/constants'
import { setNotAuthenticated } from '../../features/login/loginSlice'

import UserHistory from '../UserHistory'
import Backdrop from '../Backdrop'
import ColCard from '../ColCard'
import CollapseHorizontal from '../CollapseHorizontal'
import AbsoluteTooltip from '../AbsoluteTooltip'
import { AuditCreate } from '../../features/audit/audit-create'
import { useCookies } from 'react-cookie'

import FlipIcon from '@mui/icons-material/Flip'
import clsx from 'clsx'

import useResizeObserver from '../../hooks/useResizeObserver'
import { compare } from '../../common/utils'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ClearIcon from '@mui/icons-material/Clear'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import Autocomplete from '@mui/material/Autocomplete'

import UniversalDialog from '../UniversalDialog'

import { getLabelColorByCategory } from '../../common/utils'

import Popup from '../Popup'
import SanityLabel from '../SanityLabel'
import CheckIcon from '@mui/icons-material/Check'

import { MakeRedirect } from '../../common/utils'
import { red } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import MoodIcon from '@mui/icons-material/Mood'

const cheatSheetUrl =
  'https://docs.google.com/spreadsheets/d/1tkLcp_rx5ZUYCGfy1OPDnY_fJrxO-lb4/edit#gid=1322797949'
const warmTemplatesUrl =
  'https://docs.google.com/document/d/1SnLmc_3vMogDzzLYoul-BIPpsyypL6bi/edit'

const toolbarHeight = 50

const toolStyles = makeStyles((theme) => ({
  caliper: { transform: 'rotate(90deg)' },
  vLabelTool: { transform: 'rotate(90deg)' },
  aLabelTool: { transform: 'rotate(90deg)' },
  deleteLabel: { transform: 'rotate(90deg)' },
  gridWrapper: {
    minHeight: toolbarHeight,
  },
  container: {
    marginTop: 0,
    minHeight: toolbarHeight,
    borderRadius: 0,
    boxShadow: '0px 1px 4px 0px #00000014',
    borderTop: '1px solid rgba(0, 0, 0, .12)',
  },
  toolLabelType: {},
  toolbox: {
    flexWrap: 'nowrap',
  },
  recordLabelMenu: {
    margin: theme.spacing(),
    color: '#FFFFFF',
    backgroundColor: '#4caf50',
    '&:hover': { backgroundColor: '#357a38' },
  },
  tool: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
  },
  toolName: {
    marginLeft: theme.spacing(),
    letterSpacing: 0,
    fontSize: 12,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
  },
  caliperOutput: {
    fontSize: 12,
    opacity: 0.7,
  },
  time: {
    borderRight: '1px solid rgb(0, 0, 0, 0.12)',
  },
  labelQuantity: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderLeft: {
    borderLeft: '1px solid rgb(0, 0, 0, 0.12)',
  },
  lightColor: {
    color: '#6c748f',
    marginLeft: 3,
  },
}))

function Tool({ color, disableRipple, onClick, icon, title }) {
  const classes = toolStyles()

  return (
    <Button
      className={classes.tool}
      onClick={onClick}
    >
      {icon}
      <Typography className={classes.toolName}>{title}</Typography>
    </Button>
  )
}

function AnnotationToolbar(props) {
  const classes = toolStyles()
  const {
    activeTool,
    setActiveTool,
    currentTime,
    setActiveLabel,
    caliperState,
    data,
    setData,
    tools,
    PVCCount,
    PACCount,
    invertECG,
    // isSubmitButtonDisabled,
  } = props

  const wrapperRef = useRef(null)

  const [isSticky, setIsSticky] = useState(false)
  const [wrapperStyle, setWrapperStyle] = useState({})
  const [containerStyle, setContainerStyle] = useState({})

  const caliperVert = activeTool === 1

  let caliperDiff = null

  if (caliperState.current) {
    if (caliperVert) {
      caliperDiff = caliperState.current.yMax - caliperState.current.yMin
    } else {
      caliperDiff = caliperState.current.xMax - caliperState.current.xMin
    }
  }

  const checkForSticky = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect()
      const shouldBeSticky = rect.y <= 0
      setIsSticky(shouldBeSticky)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkForSticky)

    return () => {
      window.removeEventListener('scroll', checkForSticky)
    }
  }, [])

  useEffect(() => {
    const newWrapperStyle = isSticky ? { height: toolbarHeight } : {}
    const newContainerStyle = isSticky
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
        }
      : {}

    setWrapperStyle(newWrapperStyle)
    setContainerStyle(newContainerStyle)
  }, [isSticky])

  const calipherValue = caliperVert
    ? (caliperDiff / 100).toFixed(2)
    : parseInt(caliperDiff * 1000)

  return (
    <Box style={wrapperStyle} ref={wrapperRef}>
      <Paper className={classes.container} style={containerStyle}>
        <Grid container className={classes.gridWrapper} wrap="nowrap">
          <Grid
            container
            item
            xs={4}
            style={{ maxWidth: 420 }}
            align="center"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            className={classes.time}
          >
            <Grid
              item
              xs
              style={{ maxWidth: 120 }}
              container
              direction="column"
            >
              <Grid item>
                <Typography variant="h6" className={classes.toolLabelType}>
                  {currentTime.toFixed(2)} s
                </Typography>
              </Grid>
              {caliperState.current && (
                <Grid item>
                  <Typography className={classes.caliperOutput} align="center">
                    Caliper: {calipherValue} {caliperVert ? 'mV' : 'ms'}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              style={{ maxWidth: 300, height: '100%' }}
              xs
              justifyContent="space-around"
            >
              <Grid
                item
                xs
                container
                wrap="nowrap"
                className={clsx(classes.labelQuantity, classes.borderLeft)}
              >
                <Typography variant="h6" className={classes.toolLabelType}>
                  PVC:
                </Typography>
                <Typography
                  variant="h6"
                  className={clsx(classes.toolLabelType, classes.lightColor)}
                >
                  {PVCCount}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                container
                wrap="nowrap"
                className={classes.labelQuantity}
              >
                <Typography variant="h6" className={classes.toolLabelType}>
                  PAC:
                </Typography>
                <Typography
                  variant="h6"
                  className={clsx(classes.toolLabelType, classes.lightColor)}
                >
                  {PACCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs className={classes.toolbox}>
            {tools.map((tool, idx) => (
              <Tool
                key={idx}
                title={tool.title}
                color={activeTool === idx ? 'secondary' : 'default'}
                disableRipple={activeTool === idx}
                onClick={() => {
                  setActiveTool(idx)
                  setActiveLabel(tool.labelMapping)
                }}
                icon={
                  tool.wrapper
                    ? tool.wrapper({
                        children: React.createElement(tool.icon, {
                          className: classes[tool.name],
                        }),
                      })
                    : React.createElement(tool.icon, {
                        className: classes[tool.name],
                      })
                }
              />
            ))}
            <Tool
              color="default"
              onClick={invertECG}
              icon={<FlipIcon style={{ transform: 'rotate(90deg)' }} />}
              title="Invert ECG"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

const useStylesIB = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px -1px 2px 0px #0000000D',
    zIndex: 100,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  bigButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#fff',
    backgroundColor: '#0F56B3',
  },
  buttonDisabled: {
    opacity: 0.7,
    cursor: 'default',
  },
  gridCol1: {
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
    paddingLeft: theme.spacing() * 2,
  },
  gridCol2: {
    padding: theme.spacing() * 2,
  },
  textField: {
    width: '100%',
  },
  helpText: {
    fontSize: '1rem',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  link: {
    textDecoration: 'none',
  },
  sanityErrorsTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(),
  },
}))

function InterpretationBar(props) {
  const {
    sanityErrors,
    setSnackbarECGView,
    validateSanityError,
    warmMessageRef,
    analysisText,
  } = props
  const classes = useStylesIB()

  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)

  const [warmMessage, setWarmMessage] = useState('')
  const [warmMessageValueType, setWarmMessageValueType] = useState('value')
  const [placeholderHeight, setPlaceholderHeight] = useState(0)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [barWidth, barHeight] = useResizeObserver(wrapperRef)

  useEffect(() => {
    setPlaceholderHeight(barHeight + 8) // 8 is default theme margin
  }, [barHeight, barWidth])

  useEffect(() => {
    if (analysisText !== warmMessage) {
      setWarmMessage(analysisText)
      const isError = !analysisText.length
      validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE)
    }
  }, [analysisText])

  const isSubmitDisabled = sanityErrors.length

  const handleSubmit = () => {
    if (!isSubmitDisabled) {
      props.handleSubmit()
    }
  }

  function handleChange(e) {
    const { value } = e.target
    const isError = !value.length
    validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE)
    setWarmMessage(value)
    warmMessageRef.current = value
  }

  return (
    <Box style={{ marginTop: placeholderHeight }}>
      <Box className={classes.wrapper} ref={wrapperRef}>
        <Grid container className={classes.fullHeight} wrap="nowrap">
          <Grid
            item
            xs={7}
            className={clsx(classes.gridCol1, classes.fullHeight)}
            container
            alignItems="center"
          >
            <MultipleSelect
              ekgid={props.id}
              selectedRecordLabels={props.activeRecordLabel}
              onChange={props.onRhythmsChange}
              setSnackbarECGView={setSnackbarECGView}
            />
            <Box className={classes.helpText}>
              Need help?{' '}
              <a href={cheatSheetUrl} target="blank" className={classes.link}>
                Open cheat sheet
              </a>{' '}
              or{' '}
              <a
                href={warmTemplatesUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Open warm templates
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={5}
            className={classes.gridCol2}
            container
            alignItems="center"
          >
            <TextField
              id="outlined-basic"
              label="Warm message*"
              placeholder="Warm message*"
              variant="outlined"
              className={clsx(classes.textField, classes.fullHeight)}
              maxRows={10}
              onChange={handleChange}
              multiline
              onFocus={() => setWarmMessageValueType('defaultValue')}
              onBlur={() => setWarmMessageValueType('value')}
              {...{ [warmMessageValueType]: warmMessage }}
            />
          </Grid>
          <Grid item container style={{ flexBasis: 200 }}>
            <Grid
              item
              className={clsx(
                classes.fullWidth,
                classes.bigButton,
                isSubmitDisabled && classes.buttonDisabled
              )}
              onClick={handleSubmit}
              ref={buttonRef}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
            >
              <Grid container alignItems="center" justifyContent="center">
                <CheckIcon />
                Finish review
              </Grid>
            </Grid>
            <Popup
              open={sanityErrors.length && isMouseOver}
              anchorEl={buttonRef.current}
            >
              <Typography className={classes.sanityErrorsTitle}>
                To submit review you have to:
              </Typography>
              <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_RHYTHM_SELECTED
                )}
              >
                Select rhythm
              </SanityLabel>
              <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_WARM_MESSAGE
                )}
              >
                Add warm message
              </SanityLabel>
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.EMPTY_PQRST)}
              >
                Fill at least one PQRST input
              </SanityLabel>
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.NO_FULL_SCROLL)}
              >
                Scroll to last chart
              </SanityLabel>
            </Popup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const useStylesMS = makeStyles((theme) => ({
  listItem: {
    margin: 5,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listItemHeader: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'default',
  },
  chip: {
    margin: 2,
    border: 'none',
  },
}))

function MultipleSelect({
  selectedRecordLabels,
  ekgid,
  onChange,
  setSnackbarECGView,
}) {
  const [currentRecordLabels, setCurrentRecordLabels] = useState([])
  const [availableRecordLabels, setAvailableRecordLabels] = useState([])
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [dialogState, setDialogState] = useState({
    open: false,
    smallText: null,
    addThisRhythm: null,
  })

  const classes = useStylesMS()
  const [cookies, setCookie, removeCookie] = useCookies()
  const dispatch = useDispatch()

  const changeRecordLabels = (labels) => {
    setIsBackdropOpen(true)
    const eventType =
      labels.length < currentRecordLabels.length
        ? eventTypes.rhythm_removed
        : eventTypes.rhythm_added
    sendEvent(ekgid, eventType)

    API.post(endpoints.SETRECORDLABEL + ekgid, {
      labels: labels.map((v) => v.id),
    })
      .then(() => {
        setCurrentRecordLabels(labels)
        setIsBackdropOpen(false)
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
        if (e.response?.status === 422) {
          setSnackbarECGView(e.response.data.errors.rhythm[0])
        }
        setIsBackdropOpen(false)
      })
  }

  const handleChange = (event, value) => {
    const added =
      value.length > currentRecordLabels.length
        ? value.find((label) => !currentRecordLabels.includes(label))
        : null

    if (added?.require_confirmation) {
      const addedName = added?.name
      return setDialogState({
        open: true,
        smallText: (
          <>
            <div>
              Users are unhappy when we tell them <b>{addedName}</b>.
            </div>
            <div>
              Please double check if the scan is <b>{addedName}</b> before
              adding the label.
            </div>
            <div>
              <b>Tip</b>: If the amplitude is low, have you tried zooming into
              the scan?
            </div>
          </>
        ),
        addThisRhythm: added,
      })
    }
    changeRecordLabels(value)
  }

  useEffect(() => {
    const labels = []
    selectedRecordLabels.forEach((label) => {
      const object = availableRecordLabels.find(
        (availableLabel) => availableLabel.name === label
      )
      if (object) {
        labels.push(object)
      }
    })
    setCurrentRecordLabels(labels)
  }, [selectedRecordLabels, availableRecordLabels])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentRecordLabels)
    }
  }, [currentRecordLabels])

  useEffect(() => {
    setIsBackdropOpen(true)
    API.get(endpoints.RHYTHMS)
      .then((resp) => {
        setIsBackdropOpen(false)
        const filtered = (resp?.data?.data || [])
          .filter((data) => data.visible)
          .filter((data) => data.position > 0)
          .sort(compare)
        setAvailableRecordLabels(filtered)
      })
      .catch(() => {
        setIsBackdropOpen(false)
      })
  }, [])

  const handleAddLabelFromModal = () => {
    const labels = [...currentRecordLabels, dialogState.addThisRhythm]
    changeRecordLabels(labels)
    setDialogState({})
  }

  return (
    <div style={{ width: '100%' }}>
      <Backdrop open={isBackdropOpen} />
      <Autocomplete
        disableCloseOnSelect
        multiple
        freeSolo
        id="tags-outlined"
        options={availableRecordLabels}
        value={currentRecordLabels}
        getOptionLabel={(option) => {
          return option.name
        }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => {
          return option.name === value.name
        }}
        getOptionDisabled={(option) => option.is_header}
        renderOption={(option, state) => {
          return (
            <Box className={classes.listItem}>
              <Box>
                {!option.is_header && (
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={state.selected}
                  />
                )}
                {option.name}
              </Box>
              {!option.is_header && (
                <FiberManualRecordIcon
                  style={{ color: getLabelColorByCategory(option.category) }}
                />
              )}
            </Box>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label="Record Labels"
              placeholder="Record Labels"
            />
          )
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              icon={
                <FiberManualRecordIcon
                  style={{ color: getLabelColorByCategory(option.category) }}
                />
              }
              variant="outlined"
              key={option.name}
              label={option.name}
              deleteIcon={<ClearIcon style={{ color: '#000' }} />}
              className={classes.chip}
            />
          ))
        }}
      />
      <UniversalDialog
        bigText="Are you sure?"
        icon={{
          icon: ErrorOutlineOutlinedIcon,
          color: '#EF6C00',
        }}
        buttons={[
          {
            text: 'Cancel',
            props: {
              onClick: () => setDialogState({}),
              variant: 'outlined',
              color: 'primary',
            },
          },
          {
            text: 'Add label',
            props: {
              onClick: handleAddLabelFromModal,
              variant: 'contained',
              color: 'primary',
            },
          },
        ]}
        {...dialogState}
      />
    </div>
  );
}

function LoaderDialog(props) {
  const {
    onClose,
    submitState: sumbitStateProps,
    open,
    setForceSubmit,
    isAudit,
    onFixErrorsClick,
    handleSubmitNoValidation,
  } = props

  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies()
  const dispatch = useDispatch()
  const reviewErrors = useSelector(selectReviewErrors)
  const reviewWarnings = useSelector(selectReviewWarnings)

  const [submitState, setSubmitState] = useState(submitStates.IN_PROGRESS)

  const handleSaveDone = async () => {
    const auditQueryParam = `?audit=${isAudit ? 'true' : 'false'}`
    if (isAudit) {
      try {
        const audits = await API.get(endpoints.GET_AUDITS(0))

        if (audits.data.data.length) {
          history.push(
            `/review/${audits.data.data[0].ecg_id}${auditQueryParam}`
          )
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          return
        }

        setSubmitState(submitStates.DONE)
        return
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }
    }

    if (!isAudit) {
      try {
        const assignedTasks = await API.get(endpoints.TASKSTATUS)
        if (assignedTasks.data.data.length) {
          history.push(
            `/review/${assignedTasks.data.data[0].ecg_id}${auditQueryParam}`
          )
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }

      try {
        const availableTasks = await API.get(endpoints.GETTASKS)
        if (availableTasks.data.data.tasks_available) {
          const response = await API.get(endpoints.TASKASSIGN)
          history.push(`/review/${response.data.data.ecg_id}${auditQueryParam}`)
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }

        setSubmitState(submitStates.DONE)
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }
    }
  }

  useEffect(() => {
    if (sumbitStateProps === submitStates.DONE) {
      handleSaveDone()
    } else {
      setSubmitState(sumbitStateProps)
    }
  }, [sumbitStateProps])

  const useStyles = makeStyles((theme) => ({
    modal: {
      minWidth: 400,
    },
    inProgressModal: {
      margin: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    bigText: {
      fontSize: 16,
      color: theme.palette.grey[900],
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    smallText: {
      fontSize: 12,
      marginBottom: 0,
    },
    bigIcon: {
      fontSize: 60,
      marginRight: theme.spacing(2),
    },
  }))

  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  let errorWarningText = 'You have '
  if (reviewErrors.length) {
    errorWarningText += `${reviewErrors.length} error${
      reviewErrors.length > 1 ? 's' : ''
    }`
    if (reviewWarnings.length) {
      errorWarningText += ` and`
    }
  }
  if (reviewWarnings.length) {
    errorWarningText += ` ${reviewWarnings.length} warning${
      reviewWarnings.length > 1 ? 's' : ''
    }`
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="loader-dialog">
      {(submitState === submitStates.IN_PROGRESS ||
        submitState === submitStates.ERROR) && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      )}
      {submitState === submitStates.IN_PROGRESS && (
        <DialogContent
          className={`${classes.modal} ${classes.inProgressModal}`}
        >
          <Grid container alignItems="center">
            <Grid item>
              <CircularProgress className={classes.bigIcon} />
            </Grid>
            <Grid item>
              <DialogContentText className={classes.bigText}>
                Saving data...
              </DialogContentText>
              <DialogContentText className={classes.smallText}>
                Don't close this tab before saving ends.
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {submitState === submitStates.DONE && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <MoodIcon color="primary" className={classes.bigIcon} />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  You finished all task from the queue!
                </DialogContentText>
                <DialogContentText className={classes.smallText}>
                  There are no more tasks in the queue for you.
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={MakeRedirect(isAudit ? '/audit' : `/live`)}
              variant="contained"
            >
              Go to all tasks
            </Button>
          </DialogActions>
        </>
      )}
      {submitState === submitStates.ERROR && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <HighlightOffIcon
                  style={{ color: red[900] }}
                  className={classes.bigIcon}
                />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  We can’t save your review
                </DialogContentText>
                <DialogContentText className={classes.smallText}>
                  Check your internet connection and try again.
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setForceSubmit(Date.now())}
              variant="contained"
            >
              Retry
            </Button>
          </DialogActions>
        </>
      )}
      {submitState === submitStates.ERROR_ECG_ERROR && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <HighlightOffIcon
                  style={{ color: reviewErrors.length ? red[900] : '#EF6C00' }}
                  className={classes.bigIcon}
                />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  {errorWarningText}.
                </DialogContentText>
                {reviewErrors.map((error) => (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ fontSize: 12 }}
                  >
                    <Grid item>
                      <Typography
                        color="secondary"
                        style={{ lineHeight: 1, fontSize: 'inherit' }}
                      >
                        <HighlightOffIcon />
                        &nbsp;
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="secondary"
                        style={{ fontSize: 'inherit' }}
                      >
                        Error:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 'inherit' }}>
                        {error.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                {reviewWarnings.map((warning) => (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ fontSize: 12 }}
                  >
                    <Grid item style={{ color: '#EF6C00' }}>
                      <Typography
                        color="inherit"
                        style={{ lineHeight: 1, fontSize: 'inherit' }}
                      >
                        <HighlightOffIcon />
                        &nbsp;
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="inherit"
                        style={{ fontSize: 'inherit' }}
                      >
                        Warning:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 'inherit' }}>
                        {warning.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {reviewErrors.length ? (
              <Button
                onClick={onFixErrorsClick}
              >
                Let's fix them
              </Button>
            ) : (
              <>
                <Button
                  onClick={onFixErrorsClick}
                >
                  Let's fix them
                </Button>
                <Button
                  onClick={handleSubmitNoValidation}
                >
                  Submit with warnings
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

// constants

let CHART = null

const useStyles = makeStyles((theme) => ({
  textArea: {
    color: 'black !important',
  },
  ecgWrapper: {
    marginTop: theme.spacing() * 2,
  },
  sectionsWrapper: {
    margin: theme.spacing(),
  },
  intervalItem: {
    maxWidth: 100,
    margin: theme.spacing(),
  },
  chartWrapper: {
    // marginBottom: theme.spacing(),
  },
}))

function EKGViewer({ isAudit, forceAuditSubmit, setForceAuditSubmit }) {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies()
  const reviewErrors = useSelector(selectReviewErrors)
  const reviewWarnings = useSelector(selectReviewWarnings)

  const [snackbar, setSnackbar] = useState(null)

  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [dataAsyncRef, setDataAsyncRef] = useAsyncReference([])
  const [dataSplitted, setDataSplitted] = useState([])
  const [labels, setLabels] = React.useState({})
  const [PVCCount, setPVCCount] = useState(0)
  const [PACCount, setPACCount] = useState(0)
  const [QTC, setQTC] = useState(0)
  const [QT, setQT] = useState('')

  const [activeRecordLabel, setActiveRecordLabel] = React.useState([])
  const [notes, setNotes] = useState('')
  const [notesSaving, setNotesSaving] = useState(false)

  const [intervalLabels, setIntervalLabels] = useState([])
  const [submitState, setSubmitState] = useState(submitStates.NONE)
  const [forceSubmit, setForceSubmit] = useState(null)

  const [analysisText, setAnalysisText] = useState('')

  const [activeTool, setActiveTool] = React.useState(0)
  const [currentTime, setCurrentTime] = React.useState(0.0)
  const [caliperState, setCaliperState] = useAsyncReference(null)
  const [activeLabel, setActiveLabel] = React.useState(0)

  const [minY, setMinY] = React.useState(-1)
  const [maxY, setMaxY] = React.useState(5)

  const [isLoaderDialogOpen, setIsLoaderDialogOpen] = React.useState(false)
  const [isInverted, setIsInverted] = useState(false)
  const [intervalEnabled, setIntervalEnabled] = useState(false)

  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [isChartMounted, setIsChartMounted] = useState(false)

  const [boxType, setBoxType] = useState(boxTypes.LARGE)
  const [sanityErrors, setSanityErrors] = useState([
    sanityErrorsEnum.NO_RHYTHM_SELECTED,
    sanityErrorsEnum.NO_WARM_MESSAGE,
    sanityErrorsEnum.EMPTY_PQRST,
    sanityErrorsEnum.NO_FULL_SCROLL,
  ])

  const [chartTooltip, setChartTooltip] = useAsyncReference(null)

  const lastChartRef = useRef(null)
  const removeScrollEventRef = useRef(null)
  const chartMountedTimeoutRef = useRef(null)
  const warmMessageRef = useRef('')

  const dispatch = useDispatch()
  const params = useParams()

  const samplingFrequency = 512

  const validateSanityError = (isError, sanityErrorName) => {
    const isErrorInState = sanityErrors.includes(sanityErrorName)
    if (isError && !isErrorInState) {
      setSanityErrors((prevState) => [...prevState, sanityErrorName])
    }
    if (!isError && isErrorInState) {
      setSanityErrors((prevState) =>
        prevState.filter((error) => error !== sanityErrorName)
      )
    }
  }

  const validateLastChartScroll = () => {
    if (
      lastChartRef &&
      sanityErrors.includes(sanityErrorsEnum.NO_FULL_SCROLL)
    ) {
      const isVisible = elementInViewport(lastChartRef.current)
      if (isVisible) {
        setSanityErrors((prevState) =>
          prevState.filter((error) => error !== sanityErrorsEnum.NO_FULL_SCROLL)
        )
      }

      return isVisible
    }
  }

  useEffect(() => {
    if (!isEqual(data, dataAsyncRef)) {
      setDataAsyncRef(data)
    }
  }, [data])

  function getData(recordID) {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETRECORD + recordID)
        .then((e) => {
          let dataset = []
          e.data.data.forEach((y, i) => {
            dataset.push({ x: i / samplingFrequency, y })
          })
          setData([dataset])
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  const handleSubmit = (validation = true) => {
    if (!warmMessageRef.current?.length) {
      return
    }
    return
    const promises = [handleIntervalUpdate()]
    if (isAudit) {
      promises.push(
        API.put(endpoints.GETANNOTATIONSETTER(id), {
          annotation: warmMessageRef.current,
        })
      )
    }
    setIsBackdropOpen(true)
    Promise.all(promises)
      .then(() => {
        if (!isAudit) {
          const data = { analysis: warmMessageRef.current }
          const started_at = localStorage.getItem(params.id)
          if (started_at) {
            data.started_at = started_at
            localStorage.removeItem(params.id)
          }
          let url = endpoints.SETANALYSIS + id
          if (!validation) {
            url += '?skip_warning=yes'
          }
          return API.post(url, data)
        }
        return Promise.resolve()
      })
      .then(() => {
        handleChangeSubmitState(submitStates.IN_PROGRESS)
        setIsLoaderDialogOpen(true)
        return Promise.resolve()
      })
      .then(() => {
        handleChangeSubmitState(submitStates.DONE)
        dispatch(loadTasksAsync())
        setActiveRecordLabel([])
        isAudit && setForceAuditSubmit(Date.now())
        setIsBackdropOpen(false)
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
        if (e.response?.status === 422) {
          dispatch(setReviewErrors(e.response.data.errors.ecg))
          dispatch(setReviewWarnings(e.response.data.warnings.ecg))
          handleChangeSubmitState(submitStates.ERROR_ECG_ERROR)
          setIsBackdropOpen(false)
          setIsLoaderDialogOpen(true)
          return
        }
        handleChangeSubmitState(submitStates.ERROR)
        setIsBackdropOpen(false)
      })
  }

  const handleIntervalUpdate = () => {
    return new Promise((resolve, reject) => {
      if (intervalEnabled && id) {
        const measurements = intervalLabels.map((e) => ({
          ...e,
          measurement: parseFloat(e.measurement) || 0,
        }))
        measurements.push({
          interval_type: 'qtc',
          measurement: QTC,
        })
        return API.post(endpoints.SETINTERVALS + id, {
          measurements,
        })
          .then(() => {
            setIntervalEnabled(false)
            resolve()
          })
          .catch((e) => {
            if (e.response?.status === 401) {
              API.get(endpoints.LOGOUT).finally((e) => {
                removeCookie(SESSION_COOKIE)
                dispatch(setNotAuthenticated())
              })
            }
            reject()
          })
      } else {
        resolve()
      }
    })
  }

  const handleSubmitNoValidation = () => {
    handleSubmit(false)
  }

  useEffect(() => {
    if (typeof forceSubmit === 'number') {
      handleSubmit()
    }
  }, [forceSubmit])

  const defaultToolHandler = (event, elements, state) => {}

  const doLabelTool = (_, elements, state, options) => {
    if (elements.length < 1) return

    const { activeLabel, setLabels, labels, activeTool } = state
    const eventIndex = elements[0]._index + options.startedIndex

    const xValue = data[0][eventIndex].x
    const scaleID = elements[0]._xScale.id

    if (!(xValue in labels)) {
      const label = toolMappingReverse[activeTool] === 'VLABEL' ? 1 : 2
      sendEvent(state.id, eventTypes.beat_added)
      setIsBackdropOpen(true)
      API.post(endpoints.ADDANNOTATIONLABEL + state.id, {
        annotations: [
          {
            index: eventIndex,
            label,
            ecg_id: state.id,
          },
        ],
      })
        .then(() => {
          if (label === 1) setPVCCount((prevState) => prevState + 1)
          if (label === 2) setPACCount((prevState) => prevState + 1)
          setLabels((prevState) => ({
            ...prevState,
            [xValue]: makeAnnotation(
              xValue,
              scaleID,
              availableLabels[activeLabel].labelDisplay
            ),
          }))
          setIsBackdropOpen(false)
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          setIsBackdropOpen(false)
        })
    }
  }

  const makeAnnotation = (x, scaleID, labelDisplay) => {
    return {
      type: 'line',
      mode: 'vertical',
      scaleID: scaleID,
      value: x,
      borderColor: 'blue',
      borderWidth: 1,
      label: {
        position: 'top',
        enabled: true,
        content: labelDisplay,
      },
    }
  }

  const makeCaliperVertAnnotation = (y, xMin, xMax, xScaleID, yScaleID) => {
    return {
      type: 'box',
      xScaleID,
      yScaleID,
      yMin: y,
      yMax: y + 0.001,
      xMin,
      xMax,
      borderColor: 'rgba(158,158,158)',
      borderWidth: 1,
      backgroundColor: 'rgba(158,158,158, 0.5)',
      initial: y,
    }
  }

  const makeCaliperAnnotation = (x, yMin, yMax, xScaleID, yScaleID) => {
    return {
      type: 'box',
      xScaleID,
      yScaleID,
      xMin: x,
      xMax: x + 0.001,
      yMin,
      yMax,
      borderColor: 'rgba(158,158,158)',
      borderWidth: 1,
      backgroundColor: 'rgba(158,158,158, 0.5)',
      initial: x,
    }
  }

  const toolMapping = {
    CALIPER: 0,
    CALIPERVERT: 1,
    VLABEL: 2,
    ALABEL: 3,
    DELETE: 4,
  }

  const toolMappingReverse = {
    0: 'CALIPER',
    1: 'CALIPERVERT',
    2: 'VLABEL',
    3: 'ALABEL',
    4: 'DELETE',
  }

  const badgeFactory = (badgeContent) => {
    return (props) => (
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        overlap="circular"
        badgeContent={badgeContent}
        {...props}
      />
    );
  }

  const labelMapping = {
    VLABEL: 0,
    ALABEL: 1,
  }

  const availableLabels = [
    {
      name: 'pvc',
      labelDisplay: 'V',
      badgeDisplay: 'V',
    },
    {
      name: 'pac',
      labelDisplay: 'A',
      badgeDisplay: 'A',
    },
  ]

  function getAnnotations(recordID) {
    return new Promise((resolve, reject) => {
      API.get(
        endpoints.GETANNOTATIONLABEL + recordID + '?start=0&stop=2147483647'
      )
        .then((e) => {
          let dataset = {}
          let PVCCount = 0
          let PACCount = 0
          if (e.data?.data?.annotations?.length && data.length) {
            e.data.data.annotations.forEach((annotation) => {
              let lDisplay = ''
              switch (annotation.label) {
                case 1:
                  lDisplay = 'V'
                  PVCCount++
                  break
                case 2:
                  lDisplay = 'A'
                  PACCount++
                  break
                default:
                  lDisplay = ''
              }

              dataset[data[0][annotation.idx].x] = makeAnnotation(
                data[0][annotation.idx].x,
                'x-axis-0',
                lDisplay
              )

              setLabels(dataset)
            })
          } else if (e.data?.data?.annotations === null) {
            setLabels({})
          }
          setPVCCount(PVCCount)
          setPACCount(PACCount)
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  const handleChange = (e, name) => {
    switch (name) {
      case 'boxType':
        setBoxType(e.target.value)
        break
      default:
        break
    }
  }

  const handleChangeSubmitState = (state) => {
    setSubmitState(state)
  }

  const computeQTC = (QT, RR) => {
    let QTC = QT / Math.sqrt(RR)
    const rest = QTC - parseInt(QTC)
    if (rest >= 0.5) {
      QTC = QTC + 1
    }
    QTC = parseInt(QTC)
    setQTC(isNaN(QTC) ? 0 : QTC)
  }

  const computeQT = (QTc, RR) => {
    let QT = QTc * Math.sqrt(RR)
    const rest = QT - parseInt(QT)
    if (rest >= 0.5) {
      QT = QT + 1
    }
    QT = parseInt(QT)
    QT = isNaN(QT) ? 0 : QT
    setQT(QT)
  }

  function getIntervalPQRST(recordID) {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETINTERVALS + recordID)
        .then((data) => {
          const measurements = data.data.data.measurements

          const QT = measurements.find(
            (measurement) => measurement.interval_type === 'qt'
          )
          const QTC = measurements.find(
            (measurement) => measurement.interval_type === 'qtc'
          )

          if (QT) {
            setQT(QT.measurement)
          }
          if (QTC) {
            setQTC(QTC.measurement)
            const RR = 60 / heartRate
            computeQT(QTC.measurement, RR)
          }

          setIntervalLabels(measurements)
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  function getRecordLabel(recordID) {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETRECORDLABEL + recordID)
        .then(({ data: { data } }) => {
          setActiveRecordLabel(data.labels)
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  function getNotes(recordID) {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETNOTES + recordID)
        .then(({ data: { data: note } }) => {
          if (note) {
            setNotes(note.note)
          }
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  const getAnalysisAnnotation = (id) => {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETANALYSIS + id)
        .then((data) => {
          const { annotations } = data.data.data
          if (annotations) {
            setAnalysisText(annotations)
          }
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  const invertECG = () => {
    const newData = data[0].map(({ x, y }) => ({ x, y: -y }))
    if (caliperState.current) {
      let ncs = { ...caliperState.current }
      if (ncs.isHorizontalClipher) {
        const yMax = Math.max(...newData.map(({ y }) => y))
        const yMin = Math.min(...newData.map(({ y }) => y))
        ncs = {
          ...ncs,
          yMax,
          yMin,
        }
      } else {
        const yMax = ncs.yMax * -1
        const yMin = ncs.yMin * -1
        ncs = {
          ...ncs,
          yMax,
          yMin,
        }
      }
      setCaliperState(ncs)
    }
    setData([newData])
  }

  const doCaliperTool = (event, elements, state) => {
    if (elements.length < 1) return

    const { caliperState, setCaliperState } = state
    // No existing caliper state or caliper state finalized, so hover has no effect.
    if (
      event.type === 'mousemove' &&
      (!caliperState.current || caliperState.current.final)
    )
      return

    const eventIndex = elements[0]._index
    const datasetIndex = elements[0]._datasetIndex
    const xValue =
      elements[0]._chart.data.datasets[datasetIndex].data[eventIndex].x
    const xScaleID = elements[0]._xScale.id
    const yScaleID = elements[0]._yScale.id
    const yMin = elements[0]._yScale.min
    const yMax = elements[0]._yScale.max
    if (event.type === 'mousemove') {
      setCaliperState((prevState) => {
        const anchor = prevState.initial
        const xMin = anchor < xValue ? anchor : xValue
        const xMax = anchor > xValue ? anchor : xValue
        return { ...prevState, xMin, xMax }
      })
    }
    if (event.type === 'click') {
      // Place first caliper anchor
      if (!caliperState.current) {
        setChartTooltip(null)
        setCaliperState({
          ...makeCaliperAnnotation(xValue, yMin, yMax, xScaleID, yScaleID),
          isHorizontalClipher: true,
          pageX: event.pageX,
          pageY: event.pageY,
        })
      } else if (caliperState.current.initial && !caliperState.current.final) {
        if (caliperState.current.pageX && caliperState.current.pageY) {
          const tooltip = {
            y: caliperState.current.pageY,
            x: parseInt((caliperState.current.pageX + event.pageX) / 2),
          }
          setChartTooltip(tooltip)
        }
        setCaliperState((prevState) => {
          const anchor = prevState.initial
          const xMin = anchor < xValue ? anchor : xValue
          const xMax = anchor > xValue ? anchor : xValue
          return {
            ...prevState,
            xMin,
            xMax,
            final: xValue,
            isHorizontalClipher: true,
          }
        })
      } else {
        setCaliperState(null)
      }
    }
  }

  const doCaliperVertTool = (event, elements, state) => {
    if (elements.length < 1) return

    const { caliperState, setCaliperState } = state
    // No existing caliper state or caliper state finalized, so hover has no effect.
    if (
      event.type === 'mousemove' &&
      (!caliperState.current || caliperState.current.final)
    )
      return

    const eventIndex = elements[0]._index
    const datasetIndex = elements[0]._datasetIndex
    const yValue =
      elements[0]._chart.data.datasets[datasetIndex].data[eventIndex].y
    const yScaleID = elements[0]._yScale.id
    const xScaleID = elements[0]._xScale.id
    const xMin = elements[0]._xScale.min
    const xMax = elements[0]._xScale.max

    if (event.type === 'mousemove') {
      setCaliperState((prevState) => {
        const anchor = prevState.initial
        const yMin = anchor < yValue ? anchor : yValue
        const yMax = anchor > yValue ? anchor : yValue
        return { ...prevState, yMin, yMax }
      })
    }
    if (event.type === 'click') {
      // Place first caliper anchor
      if (!caliperState.current) {
        setCaliperState(
          makeCaliperVertAnnotation(yValue, xMin, xMax, xScaleID, yScaleID)
        )
      } else if (caliperState.current.initial && !caliperState.current.final) {
        setCaliperState((prevState) => {
          const anchor = prevState.initial
          const yMin = anchor < yValue ? anchor : yValue
          const yMax = anchor > yValue ? anchor : yValue
          return { ...prevState, yMin, yMax, final: yValue }
        })
      } else {
        setCaliperState(null)
      }
    }
  }

  // Placeholder data that will be switched to an API fetch
  // const data = TestRecord[0];

  const [heartRate, setHeartRate] = useState(null)
  const getHeartRate = (id) => {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETHEARTRATE + id)
        .then(({ data: { data } }) => {
          setHeartRate(data.avg_heart_rate)
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  useEffect(() => {
    const promises = []
    if (data.length > 0 && data[0].length > 0 && id) {
      setMinY(data.length > 0 ? Math.min(...data[0].map(({ y }) => y)) - 1 : -1)
      setMaxY(data.length > 0 ? Math.max(...data[0].map(({ y }) => y)) + 5 : 5)

      const newDataSplitted = []
      const spitFrequency = 5121 // number of coordinates per 10s
      const splitTime = 10 // seconds for one graph

      const restData = Array.from(data[0])
      for (let i = 0; i < 3; i++) {
        const elements = i !== 2 ? restData.splice(0, spitFrequency) : restData

        if (i !== 0) {
          const unshifted = []
          const prevArray = newDataSplitted[i - 1]

          let currentIndex = prevArray.length - 1
          while (
            !unshifted.find(
              (element) => element.x - parseInt(element.x) === 0
            ) &&
            currentIndex > 0
          ) {
            unshifted.unshift(prevArray[currentIndex])
            currentIndex--
          }

          // this will add last integer from previous graph
          elements.unshift(...unshifted)
        }

        // if there are no coordinate with x >= n*10, then this will add an empty coordinate, x value only
        if (elements[elements.length - 1].x < (i + 1) * splitTime) {
          elements.push({ x: (i + 1) * splitTime })
        }

        newDataSplitted.push(elements)
      }
      // uncomment this if you want to split automatically
      // const numbersOfRecords = []
      // const divisions = 3
      // const divided = data[0].length / divisions
      // for (let i = 0; i < divisions; i++) {
      //   if (i === divisions - 1) {
      //     numbersOfRecords.push(
      //       Math.ceil(divided - parseInt(divided) > 0 ? divided + 1 : divided)
      //     )
      //   } else {
      //     numbersOfRecords.push(parseInt(divided))
      //   }
      // }
      // let current = 0
      // numbersOfRecords.forEach((number) => {
      //   const dataSliced = data[0].slice(current, current + number)
      //   newDataSplitted.push(dataSliced)
      //   current += number
      // })

      setDataSplitted(newDataSplitted)
      promises.push(getAnnotations(params.id))
    }
    if (params.id !== id) {
      promises.push(setId(params.id))
      promises.push(getData(params.id))
      promises.push(getRecordLabel(params.id))
      promises.push(getNotes(params.id))
      promises.push(getHeartRate(params.id))
      promises.push(getAnalysisAnnotation(params.id))
    }

    setIsBackdropOpen(true)
    Promise.all(promises)
      .then(() => {
        // this one is there because to calculate QT from QTc first we need heartRate :/
        return getIntervalPQRST(params.id)
      })
      .then(() => {
        setIsBackdropOpen(false)
      })
      .catch(() => {
        setIsBackdropOpen(false)
      })
  }, [data, params.id])

  useEffect(() => {
    if (isChartMounted) {
      const timeoutId = setTimeout(() => {
        chartMountedTimeoutRef.current = null
        const isVisible = validateLastChartScroll()
        if (!isVisible) {
          window.addEventListener('scroll', validateLastChartScroll)
          removeScrollEventRef.current = true
        }
      }, 100)
      chartMountedTimeoutRef.current = timeoutId
    }

    return () => {
      window.removeEventListener('scroll', validateLastChartScroll)
    }
  }, [isChartMounted])

  const getClosestLabel = (labels, x) => {
    let closest = null
    for (const labelX in labels) {
      const diff = Math.abs(x - labelX)
      if (!closest || Math.abs(diff) < Math.abs(closest - x)) {
        closest = labelX
      }
    }
    return closest
  }

  const handleCustomEvent =
    (type) =>
    async (e, options = {}) => {
      if (type === 'contextMenu' || type === 'doubleClick') {
        const rect = e.target.getBoundingClientRect()
        const offsetLeft = rect.x
        const realX = e.clientX - offsetLeft - options.layoutPadding.left - 10 // 10px is left offset

        const minX = Math.min(...options.data[0].map(({ x }) => x))
        const maxX = Math.max(...options.data[0].map(({ x }) => x))

        const contextXPercent =
          realX /
          (rect.width -
            options.layoutPadding.left -
            options.layoutPadding.right -
            10)

        let contextX = contextXPercent * (maxX - minX) + minX
        if (contextX < minX) {
          contextX = minX
        }
        if (contextX > maxX) {
          contextX = maxX
        }

        let closest = null
        setLabels((prevState) => {
          closest = getClosestLabel(prevState, contextX)
          const newLabels = { ...prevState }
          delete newLabels[closest]
          return newLabels
        })

        const index = data[0].findIndex(({ x }) => String(x) === closest)

        setIsBackdropOpen(true)
        try {
          await API.post(endpoints.ADDANNOTATIONLABEL + id, {
            annotations: [
              {
                index,
                label: 0,
              },
            ],
          })
          sendEvent(id, eventTypes.beat_removed)
          await getAnnotations(id)
          setIsBackdropOpen(false)
        } catch (e) {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          setIsBackdropOpen(false)
        }
      }
    }

  const handleContextMenu = (...args) => {
    handleCustomEvent('contextMenu')(...args)
  }

  const handleDoubleClick = (...args) => {
    handleCustomEvent('doubleClick')(...args)
  }

  const handleKeyDown = (e) => {
    const inputInPath = e.path.find((el) => el instanceof HTMLInputElement)
    const isArrowLeft = e.code === 'ArrowLeft'
    const isArrowRight = e.code === 'ArrowRight'

    const isChangeable =
      caliperState?.current?.isHorizontalClipher &&
      typeof caliperState.current.final === 'number' &&
      typeof caliperState.current.initial === 'number' &&
      !inputInPath &&
      (isArrowLeft || isArrowRight)

    if (isChangeable) {
      const newCaliperState = { ...caliperState.current }
      const { final, initial } = caliperState.current

      const maxX = Math.max(...dataAsyncRef.current[0].map(({ x }) => x))
      const minX = Math.min(...dataAsyncRef.current[0].map(({ x }) => x))
      const diff = Math.abs(final - initial)

      if (isArrowRight) {
        newCaliperState.initial = final
        newCaliperState.xMin = newCaliperState.initial
        newCaliperState.final = final + diff
        newCaliperState.xMax = newCaliperState.final

        const outOfRange = newCaliperState.final > maxX
        if (outOfRange) {
          newCaliperState.final = maxX
          newCaliperState.xMax = newCaliperState.final
          newCaliperState.initial = maxX - diff
          newCaliperState.xMin = newCaliperState.initial
        }
      }
      if (isArrowLeft) {
        newCaliperState.initial = initial - diff
        newCaliperState.xMin = newCaliperState.initial
        newCaliperState.final = initial
        newCaliperState.xMax = newCaliperState.final

        const outOfRange = newCaliperState.initial < minX
        if (outOfRange) {
          newCaliperState.final = minX + diff
          newCaliperState.xMax = newCaliperState.final
          newCaliperState.initial = minX
          newCaliperState.xMin = newCaliperState.initial
        }
      }

      setCaliperState(newCaliperState)
      setChartTooltip(null)
    }
  }

  const onLineChartMount = () => {
    if (!isChartMounted) {
      setIsChartMounted(true)
    }
  }

  const onRhythmsChange = (currentRhythms) => {
    const isError = !currentRhythms.length
    validateSanityError(isError, sanityErrorsEnum.NO_RHYTHM_SELECTED)
  }

  const tools = [
    {
      name: 'caliper',
      icon: HeightIcon,
      title: 'Caliper Horizontal',
      clickHandler: defaultToolHandler,
    },
    {
      name: 'caliperVert',
      icon: HeightIcon,
      title: 'Caliper Vertical',
      clickHandler: defaultToolHandler,
    },
    {
      name: 'vLabelTool',
      icon: LabelImportantIcon,
      title: 'PVC Label',
      clickHandler: doLabelTool,
      wrapper: badgeFactory(availableLabels[labelMapping.VLABEL].badgeDisplay),
      labelMapping: labelMapping.VLABEL,
    },
    {
      name: 'aLabelTool',
      icon: LabelImportantIcon,
      title: 'PAC Label',
      clickHandler: doLabelTool,
      wrapper: badgeFactory(availableLabels[labelMapping.ALABEL].badgeDisplay),
      labelMapping: labelMapping.ALABEL,
    },
  ]

  const toolState = {
    activeTool,
    setActiveTool,
    currentTime,
    setCurrentTime,
    labels,
    setLabels,
    caliperState,
    setCaliperState,
    activeLabel,
    setActiveLabel,
    activeRecordLabel,
    setActiveRecordLabel,
    minY,
    maxY,
    setMaxY,
    setMinY,
    id: id || '',
    data,
    setData,
    setIsInverted,
    isInverted,
  }

  useEffect(() => {
    if (notesSaving && notes.length > 0 && id) {
      API.post(endpoints.SETNOTES + id, {
        note: notes,
      })
        .then(() => {
          setNotesSaving(false)
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
        })
    }
  }, [notes, notesSaving])

  const intervalTypes = ['p', 'qrs', 'pr']

  const handleFixErrorsClick = () => {
    setIsLoaderDialogOpen(false)
    window.scrollTo(0, 0)
  }

  const handleCloseSnackbar = () => {
    setSnackbar(null)
  }

  useEffect(() => {
    const isError = !(
      intervalTypes.some(
        (type) =>
          intervalLabels.find((label) => label.interval_type === type)
            ?.measurement
      ) || QT
    )
    validateSanityError(isError, sanityErrorsEnum.EMPTY_PQRST)
  }, [intervalLabels, QT, heartRate, intervalTypes])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      chartMountedTimeoutRef.current &&
        clearTimeout(chartMountedTimeoutRef.current)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  let errorWarningText = 'You have '
  if (reviewErrors.length) {
    errorWarningText += `${reviewErrors.length} error${
      reviewErrors.length > 1 ? 's' : ''
    }`
    if (reviewWarnings.length) {
      errorWarningText += ` and`
    }
  }
  if (reviewWarnings.length) {
    errorWarningText += ` ${reviewWarnings.length} warning${
      reviewWarnings.length > 1 ? 's' : ''
    }`
  }

  return <>
    <Backdrop open={isBackdropOpen} />
    <CssBaseline />
    <AnnotationToolbar
      {...toolState}
      chart={CHART}
      tools={tools}
      isSubmitButtonDisabled={intervalEnabled}
      PVCCount={PVCCount}
      PACCount={PACCount}
      invertECG={invertECG}
    />
    <CollapseHorizontal
      title="Audit tool"
      className={classes.sectionsWrapper}
      maxWidth={500}
      solidOnly={!isAudit}
      solid={
        <div>
          <Grid container direction="column">
            {(!!reviewErrors.length || !!reviewWarnings.length) && (
              <Grid item xs={12}>
                <ColCard title={errorWarningText}>
                  {reviewErrors.map((error) => (
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item>
                        <Typography
                          color="secondary"
                          style={{ lineHeight: 1 }}
                        >
                          <HighlightOffIcon />
                          &nbsp;
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography color="secondary">
                          Error:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{error.text}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {reviewWarnings.map((warning) => (
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item style={{ color: '#EF6C00' }}>
                        <Typography color="inherit" style={{ lineHeight: 1 }}>
                          <HighlightOffIcon />
                          &nbsp;
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography color="inherit">
                          Warning:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{warning.text}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </ColCard>
              </Grid>
            )}
            <Grid item container xs={12} wrap="nowrap">
              <Grid item xs={isAudit ? 12 : 6}>
                <ColCard title="Note">
                  <Typography>
                    {notes
                      ? notes
                      : 'User’s notes on symptoms or other context around the time of this recording.'}
                  </Typography>
                </ColCard>
              </Grid>
              <Grid item xs={isAudit ? 12 : 6}>
                <ColCard title="PQRST">
                  <Grid container alignItems="center">
                    {heartRate && (
                      <Grid item className={classes.intervalItem}>
                        <Typography>HR: {heartRate} bpm</Typography>
                      </Grid>
                    )}
                    {intervalTypes.map((il) => {
                      return (
                        <Grid item className={classes.intervalItem}>
                          <TextField
                            label={il.toUpperCase()}
                            placeholder={il.toUpperCase()}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>ms</InputAdornment>
                              ),
                            }}
                            value={
                              (
                                intervalLabels.find(
                                  ({ interval_type }) => interval_type === il
                                ) || {}
                              ).measurement || ''
                            }
                            onChange={({ target: { value } }) => {
                              if (
                                parseInt(value) > 1000 ||
                                !/^[-+]?[0-9]+$|^$/g.test(value)
                              ) {
                                return
                              }
                              setIntervalLabels([
                                {
                                  interval_type: il,
                                  measurement: value,
                                },
                                ...intervalLabels.filter(
                                  ({ interval_type }) => interval_type !== il
                                ),
                              ])

                              setIntervalEnabled(true)
                            }}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item className={classes.intervalItem}>
                      <TextField
                        label={'QT'}
                        placeholder={'QT'}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment>ms</InputAdornment>,
                        }}
                        value={QT}
                        onChange={({ target: { value } }) => {
                          if (
                            parseInt(value) > 1000 ||
                            !/^[-+]?[0-9]+$|^$/g.test(value)
                          ) {
                            return
                          }

                          setQT(value)
                          setIntervalEnabled(true)

                          if (value === '') {
                            if (QTC !== 0) {
                              setQTC(0)
                            }
                            return
                          }

                          const RR = 60 / heartRate
                          if (typeof RR === 'number') {
                            computeQTC(Number(QT), RR)
                          }
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.intervalItem}>
                      <Typography>QTC: {QTC} ms</Typography>
                    </Grid>
                  </Grid>
                </ColCard>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.ecgWrapper}>
              <ColCard
                title="ECG Strip"
                renderHeader={{
                  withoutTitle: false,
                  render: () => (
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{ width: 'auto' }}
                    >
                      <Grid item>
                        <FormLabel style={{ marginRight: 10 }}>
                          Box type:
                        </FormLabel>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          aria-label="boxType"
                          name="boxType"
                          value={boxType}
                          onChange={(e) => handleChange(e, 'boxType')}
                        >
                          {getKeysArray(boxTypes).map((type) => (
                            <FormControlLabel
                              value={type}
                              control={<Radio color="primary" />}
                              label={`${capitalizeFirstLetter(type)} box`}
                              labelPlacement="end"
                              size="small"
                            />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  ),
                }}
              >
                {dataSplitted.map((data, i) => {
                  const startedIndex = i * 5116 // max index when 10 sec intervals
                  return (
                    <Box className={classes.chartWrapper}>
                      <LineChart
                        onDoubleClick={handleDoubleClick}
                        onContextMenu={handleContextMenu}
                        key={i}
                        data={[data]}
                        labels={labels}
                        caliperState={caliperState}
                        tools={tools}
                        activeTool={activeTool}
                        doCaliperTool={doCaliperTool}
                        doCaliperVertTool={doCaliperVertTool}
                        setCurrentTime={setCurrentTime}
                        toolMapping={toolMapping}
                        toolState={toolState}
                        isBig={boxType === boxTypes.LARGE}
                        maxY={maxY}
                        minY={minY}
                        startedIndex={startedIndex}
                        onMount={onLineChartMount}
                      />
                    </Box>
                  )
                })}
                <Box ref={lastChartRef} />
              </ColCard>
            </Grid>
          </Grid>
        </div>
      }
      resizing={<AuditCreate forceSubmit={forceAuditSubmit} />}
    />
    {!!isAudit && <UserHistory />}
    <InterpretationBar
      handleSubmit={handleSubmit}
      analysisText={analysisText}
      warmMessageRef={warmMessageRef}
      id={id || ''}
      activeRecordLabel={activeRecordLabel}
      sanityErrors={sanityErrors}
      onRhythmsChange={onRhythmsChange}
      setSnackbarECGView={setSnackbar}
      validateSanityError={validateSanityError}
    />
    <AbsoluteTooltip state={chartTooltip.current} doNotRender={!caliperState}>
      <div>
        Caliper:{' '}
        {!!(caliperState.current?.xMax && caliperState.current?.xMin) &&
          parseInt(
            (caliperState.current.xMax - caliperState.current.xMin) * 1000
          )}
      </div>
      <div>Heart rate: {heartRate}</div>
    </AbsoluteTooltip>
    <LoaderDialog
      open={isLoaderDialogOpen}
      onClose={() => setIsLoaderDialogOpen(false)}
      submitState={submitState}
      setForceSubmit={setForceSubmit}
      isAudit={isAudit}
      onFixErrorsClick={handleFixErrorsClick}
      handleSubmitNoValidation={handleSubmitNoValidation}
    />
    <Snackbar
      open={snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity="error">
        {snackbar &&
          snackbar
            .split('')
            .map((letter, i) => (i ? letter : letter.toUpperCase()))
            .join('')}
      </Alert>
    </Snackbar>
  </>;
}

export default EKGViewer
